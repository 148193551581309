@import 'globals';
@include react-md-everything;
@include host-material-icons('~material-design-icons-iconfont/dist/fonts');

body {
  margin: 0;
}

// Utils
@import 'PageSpinner';
@import 'DataTableSpinner';
@import 'TablePagination';
@import 'InfoMessage';
@import 'Snackbar';

// Navigation
@import 'Drawer.scss';
@import 'NavigationTitle';

// Auth
@import 'SignInPage';
@import 'SignedUserDropDown';

// Pages
@import 'AdminPage';
@import 'AdminTools';
@import 'NotFoundPage';

// Organizations
@import 'OrgsSearchBar';
@import 'OrgUsersSearchBar';
@import 'OrgStatus';
@import 'OrgDetails';
@import 'OrgsDataTable';
@import 'OrgUsersDataTable';
@import 'OrgGuidesDataTable';
@import 'OrgUserItems';
@import 'OrgFilterItems';
@import 'OrgFilters';
@import 'OrgGuidesModal';

// Guides
@import 'GuidesDataTable';
@import 'GuidesSearchBar';
@import 'GuideDetails';
@import 'GuideOrgsModal';
@import 'GuideDrawer';
@import 'GuideDeleteConfirmationModal';
@import 'GuideOrgsSearchBar';
