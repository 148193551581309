.InfoMessage {
  margin-bottom: 20px;
  padding: 10px 24px 10px 10px;
  border-left: 5px solid #ffe564;
  background-color: rgba(255, 229, 100, 0.2);

  &Icon {
    width: 20px;
    vertical-align: middle;
    margin-right: 10px;
  }

  & span {
    vertical-align: middle;
  }
}
