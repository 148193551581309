.GuideSearchBar {
    display: flex;

    & > div {
      flex: 1 1 auto;
    }

    &Input {
      flex: 1 1 40%;
    }
  }

.GuideSearchInput {
  position: relative;
  top: -5px;
}
