.CreateEditGuideDrawer {
  &__buttons {
    margin-top: 0;
    margin-bottom: 0;
  }

  &__form {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    height: calc(100% - 64px);
  }

  &__spacer {
    flex: 1;
  }
}
