.OrgsSearchBar {
  display: flex;
  justify-content: space-between;

  //& > div {
  //  flex: 1 1 auto;
  //}

  &Input {
    flex: 0 1 40%;
  }
}
