.OrgDetails {

  .md-grid {
    padding: 0;
    align-items: flex-end;
  }

  .OrgDetailsSubtitle {
    font-size: 1em;
    color: #777;
    margin-bottom: 5px;
  }

  .OrgTrialIndicator {
    border-radius: 5px;
    padding: 3px 5px;
    color: #fff;
    font-size: 0.8em;

    &--active {
      background: rgb(84, 194, 89);
    }

    &--expired {
      background: rgb(224, 90, 90);
    }
  }
  .OrgName {
    color:#555555
  }
  .OrgID {
    width: 300px
  }
  .orgOffset {
    height: 65px;
  }
  .OrgsPlanField {
    display: flex;
    align-items: flex-end;
    color: black;
    button {
      flex: 0 0 auto;
      margin-left: 10px;
    }
  }

  .OrgsStatusField {
    button {
      margin-left: 10px;
      vertical-align: middle;
    }
  }

  .OrgsWebVersionField {
    display: flex;
    align-items: center;
    & > .md-progress {
      margin: 16px 0 0 16px;
    }
  }

  .OrgsFeatureToggle {
    display: flex;
    align-items: center;
    & > .md-progress {
      margin: 16px 0 0 16px;
    }
  }

  .ExtendTrialButton {
    min-width: 120px;
  }

  .UpgradePlanButton {
    min-width: 100px;
  }

  .OrgSeatsField {
    display: flex;
    align-items: flex-end;

    .activeUsers {
      width:95px;
      cursor: not-allowed;
    }
    .totalSeats {
      width:80px
    }

    & > .md-btn {
      flex: 0 0 auto;
      margin-left: 10px;
    }

    & > .md-progress {
      margin: 0 0 10px 15px;
    }

  }

  .LeadFinderLimitField {
    display: flex;
    align-items: flex-end;
    padding-left: 15px;

    &_limit {
      width: 200px
    }

    & > .md-btn {
      flex: 0 0 auto;
      margin-left: 10px;
    }

    & > .md-progress {
      margin: 0 0 10px 15px;
    }
  }
}
