.OrgStatus {
  padding: 3px 6px;
  border-radius: 5px;
  color: #fff;
  display: inline-block;
  min-width: 62px;
  text-align: center;
  vertical-align: middle;

  &--active {
    background: rgb(84, 194, 89);
  }

  &--inactive {
    background: rgb(224, 90, 90);
  }
}
