.OrgGuidesModal {
  &__subTitle {
    color: #677788;
  }

  &__list {
    display: block;
    max-height: 300px;
    overflow: auto;
  }

  &__item {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 1rem;

    &:not(:last-child) {
      margin-bottom: 1rem;
    }

    &-checkbox {
      flex-shrink: 0;
    }

    &-info {
      flex: 1;
      min-width: 0;
      display: flex;
      flex-direction: column;
      gap: 0.25rem;

      &-title {
        color: #3F4659;
        font-size: 14px;
      }

      &-flowId,
      &-description {
        color: #677788;
        font-size: 14px;
      }

      & > div {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}
