.OrgUsersDataTable {
  table-layout: fixed;
  & .md-table-checkbox {
    width: 64px;
  }

  .NoResults {
    line-height: 20px;
    width: 950px;
    text-align: center;
    padding:10px;

  }
  .ColumnUserName {
    width:200px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .ColumnUserEmail {
    width: 300px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .ColumnUserPhone {
    width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .ColumnUserRole {
    width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .ColumnUserDeleted {
    width: 70px;
  }
}
