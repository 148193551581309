.GuideOrgsModal {

  &__subTitle {
    color: #677778;
  }

  &__list {
    display: block;
    max-height: 300px;
    overflow: auto;
  }

  &__item {
    width: 100%;

    & > .md-selection-control-label {
      width: 100%;
      justify-content: space-between;
    }
  }
}
