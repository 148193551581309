.OrgFiltersItems {
    .header {
        width: 100%;
    }
}
.FilterPanel {
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}
.CheckBox {
    color: $md-light-blue-500;
}