.DataTableSpinner {
  position: relative;

  .DataTableSpinnerOverlay {
    position: absolute;
    display: flex;
    align-items: center;
    width: 100%;
    top: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.5);
  }
}
