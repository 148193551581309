.OrgGuidesDataTable {
  table-layout: fixed;
  & .md-table-checkbox {
    width: 64px;
  }

  .NoResults {
    line-height: 20px;
    width: 950px;
    text-align: center;
    padding:10px;

  }

  .ColumnFrigadeId {
    width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .ColumnGuideId {
    width: 50px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .ColumnGuideType {
    width: 50px;
  }

  .ColumnName {
    width: 350px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .ColumnDisplayName {
    width: 350px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
