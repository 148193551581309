.AdminTools {
  display: flex;
  flex-wrap: wrap;

  & > div {
    flex: 1 1 50%;
  }
}

.AdminToolsItem {
  display: flex;
  min-width: 600px;
  align-items: center;
  padding: 20px 15px;
  border-radius: 5px;

  & > div {
    flex: 0 1 auto;
  }

  &:hover {
    background: #eee;
  }

  & .AdminToolsItemIcon {
    align-self: flex-start;
    padding-right: 20px;

    & .md-icon {
      font-size: 20px;
    }
  }

  & .AdminToolsItemContent {
    flex-grow: 1;

    & h3 {
      margin-bottom: 5px;
    }

    & p {
      font-size: 1.1em;
      // max-width: 500px;
    }
  }

  & .AdminToolsItemValue {
    font-size: 1.2em;
    font-weight: bold;
    flex-basis: 150px;
    text-align: center;
  }

  & .AdminToolsItemButton {
    flex-shrink: 0;
    text-align: right;
  }
}
