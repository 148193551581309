.OrgsDataTable {
  table-layout: fixed;

  tbody tr {
    cursor: pointer;
  }
  .NoResults {
    line-height: 20px;
    width: 950px;
    text-align: center;
    padding:10px;
    
  }
  .ColumnOrgId {
    width: 115px;
    // text-overflow: ellipsis;
    // overflow: hidden;
  }

  .ColumnOrgName {
    width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  
  .ColumnOrgCreatedAt {
    width:150px;
  }
  .ColumnOrgStatus {
    width: 115px;
  }

  .ColumnOrgPlan {
    width: 150px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .ColumnActiveUsers {
    width:100px;
  }

  .ColumnOrgEmail {
    width: 300px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  
  
}



