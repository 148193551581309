.SignInPage {
  height: 100vh;

  .SignInPageWrapper {
    text-align: 'center';

    & > div {
      width: 400px;
      margin: auto;
      padding: 50px 20px;
      box-sizing: border-box;
      border: 1px solid #eee;
      box-shadow: 4px 4px 10px 0px #ccc;
      text-align: center;
      border-radius: 5px;
    }
  }

  .SignInLogo {
    width: 300px;
  }

  .SignInTitle {
    margin-top: 50px;
    margin-bottom: 100px;
    font-size: 3em;
    font-weight: bold;
    color: #555;
  }

  .SignInButton {
    font-size: 1.2em;
    padding: 10px 16px;
    height: auto;
  }
}
