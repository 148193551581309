.OrgSearchBar {
    display: flex;
  
    & > div {
      flex: 1 1 auto;
    }
  
    &Input {
      flex: 1 1 40%;
    }
  }
  
.OrgSearchInput {
  position:relative;
  top:-5px;
}