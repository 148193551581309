.NotFoundPage {
  text-align: center;
  display: grid;
  height: 100vh;
  align-items: center;

  h1 {
    font-size: 5em;
    margin-bottom: 50px;
  }

  h2 {
    font-size: 2em;
    margin-bottom: 80px;
  }
}
