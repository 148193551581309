.GuidesDataTable {
  table-layout: fixed;

  tbody tr {
    cursor: pointer;
  }

  .NoResults {
    line-height: 20px;
    width: 950px;
    text-align: center;
    padding:10px;

  }

  .ColumnFrigadeId {
    width: 150px;
  }

  .ColumnGuideType {
    width: 50px;
  }

  .ColumnGuideInnerName {
    width: 300px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .ColumnGuideDisplayName {
    width: 300px;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .ColumnGuideActiveOrgs {
    width: 100px;
  }

  .ColumnGuideUpdatedAt {
    width: 200px;
  }
}


