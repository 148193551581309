.GuideDetails {

  .md-grid {
    padding: 0;
    align-items: flex-end;
  }

  .GuideDetailsSubtitle {
    font-size: 1rem;
    color: #777;
    display: flex;
    align-items: center;
  }

  .GuideTrialIndicator {
    border-radius: 5px;
    padding: 3px 5px;
    color: #fff;
    font-size: 0.8em;

    &--active {
      background: rgb(84, 194, 89);
    }

    &--expired {
      background: rgb(224, 90, 90);
    }
  }
  .GuideName {
    color:#555555
  }
  .GuideID {
    width: 120px
  }
  .guideOffset {
    height: 65px;
  }
  .GuidesPlanField {
    display: flex;
    align-items: flex-end;
    color: black;
    button {
      flex: 0 0 auto;
      margin-left: 10px;
    }
  }

  .GuidesStatusField {
    button {
      margin-left: 10px;
      vertical-align: middle;
    }
  }

  .GuidesWebVersionField {
    display: flex;
    align-items: center;
    & > .md-progress {
      margin: 16px 0 0 16px;
    }
  }

  .GuidesFeatureToggle {
    display: flex;
    align-items: center;
    & > .md-progress {
      margin: 16px 0 0 16px;
    }
  }

  .ExtendTrialButton {
    min-width: 120px;
  }

  .UpgradePlanButton {
    min-width: 100px;
  }

  .GuideSeatsField {
    display: flex;
    align-items: flex-end;

    .activeUsers {
      width:95px;
      cursor: not-allowed;
    }
    .totalSeats {
      width:80px
    }

    & > .md-btn {
      flex: 0 0 auto;
      margin-left: 10px;
    }

    & > .md-progress {
      margin: 0 0 10px 15px;
    }

  }

  .LeadFinderLimitField {
    display: flex;
    align-items: flex-end;
    padding-left: 15px;

    &_limit {
      width: 200px
    }

    & > .md-btn {
      flex: 0 0 auto;
      margin-left: 10px;
    }

    & > .md-progress {
      margin: 0 0 10px 15px;
    }
  }
}
