// The styles below are necessary to fix a
// positioning bug with the pagination control

.md-table-footer--pagination tr td {
  padding-right: 0;
  text-align: right;
}

.md-table-pagination {
  height: 0;
}

.md-table-pagination--controls {
  height: 56px;
  position: static;
  margin-left: 0 !important;
}
