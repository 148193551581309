.OrgDrawer {
    .md-toolbar--action-right {
        width: 100%;
    }
}

.orgUserItemsDrawerContainer {
    width: 100%;
}

.orgUserItemsDrawerHeader {
    padding-left: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
        margin-top: 16px;
    }

}

.roleSelect,
.leadFinderLimitField,
.activeCheckInField,
.userEmailServiceField,
.mileageTrackingServiceField {
    padding: 0 16px;
    width: 100%;
}
